import React from "react"
import PropTypes from "prop-types"
import { alpha } from "@theme-ui/color"
import { useColorMode } from "theme-ui"
import Box from "./box"

const Letter = ({ clipBottom, children }) => {
  const [colorMode] = useColorMode()

  return (
    <Box
      sx={{
        py: [6, 12],
        pl: [6, 12],
        pr: 6,
        backgroundColor: "muted",
        fontFamily: "mono",
        backgroundImage: [
          (theme) => `linear-gradient(90deg, transparent 12px, ${alpha(
            "negative",
            0.7
          )(theme)} 14px, transparent 12px), 
          linear-gradient(${
            colorMode === "light"
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(255, 255, 255, 0.05)"
          } .1em, transparent .1em)`,
          (theme) => `linear-gradient(90deg, transparent 24px, ${alpha(
            "negative",
            0.7
          )(theme)} 26px, transparent 24px),
          linear-gradient(${
            colorMode === "light"
              ? "rgba(0, 0, 0, 0.05)"
              : "rgba(255, 255, 255, 0.05)"
          } .1em, transparent .1em)`,
        ],
        backgroundSize: "100% 1.2em",
        clipPath: clipBottom
          ? "polygon(100% 94.05%, 97.58% 96.25%, 95.02% 92.12%, 92.3% 95.21%, 89.44% 91.94%, 87.44% 95.45%, 84.57% 92.67%, 81.03% 96.64%, 77.09% 87.09%, 73.38% 96.58%, 70.42% 92.08%, 67.82% 96.01%, 65.21% 90.69%, 63.87% 95%, 59.85% 96.43%, 56.95% 91.74%, 53.92% 95.83%, 50.61% 94.64%, 46.93% 97.12%, 43.29% 92.92%, 39.34% 96.76%, 36.94% 92.26%, 32.49% 90.48%, 30.22% 97.62%, 24.82% 92.26%, 20.5% 99.85%, 16.99% 97.02%, 13.33% 100%, 8.8% 97.02%, 4.35% 99.54%, 0% 95%, 0% 0%, 100% 0px)"
          : null,
      }}
    >
      {children}
    </Box>
  )
}

Letter.propTypes = {
  clipBottom: PropTypes.bool,
}

Letter.defaultProps = {
  clipBottom: false,
}

export default Letter
